.evaluation-container {
    display: flex;
    padding: 0 7% 0 7%;
    margin-top: 30px;
    justify-content: space-between;
}
.left-section-ratings {
    width : 31%;
    min-width: 300px;
}
.left-section-rt-container {
    width: 65%
}
.left-section-card-rt {
    height: 424px;
    background-color: #F5FBFB;
    border: 1px solid #CDE9E6;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 24px 30px 24px 30px
}
.left-section-rt-header {
    width: 100%;
    height: 30px;
    border-radius: 15px;
}
.note-seller-s {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 16px;
}
.reviews-count {
    height: 50px;
    font-size: 18px;
    line-height: 48px;
    margin: auto;
}
.reviews-values {
    margin-top: 25px;
}
.review-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}
.review-label {
    color: #545C84;
    font-size: 18px;
}
.review-value {
    color: #21062E;
    font-size: 18px;
}

.evaluation-card-container {
    width: 100%;
    border: 1px solid #CDE9E6;
    margin-bottom: 20px;
}
.evaluation-card-header {
    width: 100%;
    height: 100px;
    border-bottom: 1px solid #CDE9E6;
    background-color: #F5FBFB;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 0px 8px 20px;
}
.evaluation-card-username {
    color: #21062E;
    font-size: 20px;
    text-align: left;
}
.evaluation-card-date {
    color: #21062E;
    font-size: 12px;
    text-align: left;
    margin-top: 8px;
}
.evaluation-card-rating {
    font-size: 18px;
    text-align: left;
    margin-top: 8px;
}

.evaluation-card-comment {
    width: 100%;
    background-color: #FFFFFF;
    color: #707070;
    text-align: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 12px 27px 13px 20px;
}
.recu-laiss {
    width: 100%;
    height: 30px;
    margin-bottom: 30px;
    display: flex;
    border-radius: 15px;
    border: 1px solid #CDE9E6;
}
.r-selected {
    background-color: #5ED5C8;
    z-index: 2;
    color: #FFFFFF;
    width: 50%;
    border-radius: 15px;
    padding-top: 5px;
}
.not-r-selected {
    background-color: #F5FBFB;
    z-index: 1;
    color: #9199C1;
    width: 50%;
    cursor: pointer;
    border-radius: 15px;
    padding-top: 5px;
}