.dashboard-container {
    padding-right: 6.6%;
    padding-left: 6.6%;
    padding-top: 25px;
    margin-bottom: 300px;
}
.dashboard-title {
    color: #21062E;
    margin: auto;
    font-size: 26px;
    margin-bottom: 20px;
}
.stats-container {
    width: 100%;
    height: 190px;
    background-color: #F5FBFB;
    display: flex;
    padding-top: 24px;
}
.stat-dash {
    width: 25%
}
.stat-icon-container {
    height: 70px;
    width: 70px;
    background-color: #fff;
    border-radius: 50%;
    margin: auto;
    display: flex;
    justify-content: center;
}
.stat-number {
    color: #21062E;
    font-size: 40px;
    font-weight: 500;
    opacity: 1;
}
.stat-label {
    color: #21062E;
    opacity: 1;
    font-size: 14px;
    font-weight: 500;
}
.homepage-ctr {
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-top: 30px;
}
.messages-table-head {
    width: 100%;
    background-color: #3B3D41;
    height: 40px;
    display: flex;
    text-align: start;
}
.messages-table {
    width: 100%;
    margin-top: 60px;
}
.messages-table-title {
    color:#21062E;
    font-size: 26px;
    margin-bottom: 14px
}
.messages-line {
    width: 100%;
    background-color: #F5FBFB;
    height: 85px;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
}
.messages-emitter {
    width: 23%;
    color: #22062E;
    font-size: 18px;
    margin: auto;
}
.divider-messages {
    border-right: 1px solid #CDE9E6 ;
    height: 100%
}
.message-content {
    width: 52%;
    padding-left: 18px;
    padding-top: 10px;
}
.message-date {
    width: 12%;
    margin: auto;
    color: #22062E;
    font-size: 16px
}
.message-actions {
    width: 12%;
    margin: auto;
}
.message-offer-title {
    top: 751px;
    left: 373px;
    width: 465px;
    height: 23px;
    text-align: left;
    letter-spacing: 0px;
    color: #22062E;
    opacity: 1;
    font-size: 16px;
}
.message-offer-content {
    top: 773px;
    left: 373px;
    width: 475px;
    height: 41px;
    text-align: left;
    font: normal normal 300 14px/17px Jost;
    letter-spacing: 0px;
    color: #3C3C41;
    opacity: 1;
    font-size: 14px;
}
.most-visited-line {
    width: 100%;
    height: 40px;
    display: flex;
}

.most-visited-title {
    width: 30%;
    text-align: start;
    padding-left: 20px;
    padding-top: 10px;    
}

.most-visited-date {
    width: 14%;
    padding-top: 10px;
}
.most-visited-views {
    width: 7.5%;
    padding-top: 10px;    
}
.most-visited-clicks {
    width: 7.5%;
    padding-top: 10px;    
}
.most-visited-received {
    width: 7.5%;
    padding-top: 10px;    
}
.most-visited-waiting {
    width: 11%;
    padding-top: 10px;    
}
.most-visited-actions {
    width: 21%;
    padding-top: 10px;  
    display: flex;
    justify-content: center;
    padding-left: 10px;
    padding-right: 10px;  
}
.promote-button-stats {
    height: 30px;
    background: transparent linear-gradient(45deg, #9916C7 0%, #9916C7 1%, #C032FC 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    width: 105px;
    color: #fff;
    font-size: 16px;
    width: 44%;
    position: relative;
    transform: translate(20px, -5px);
    cursor: pointer;
}