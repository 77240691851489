.selected {
    border-bottom:none;
    border-left: 1px solid #CDE9E6;
    border-right: 1px solid #CDE9E6;
    border-top: 1px solid #CDE9E6;
    min-width: 115px;
    border-radius: 5px 5px 0px 0px;
    height:40px;
    vertical-align: middle;
    color: #63D4C7;
    font-size: 16px;
    opacity: 1;
}

.notSelected {
    border-bottom:1px solid #CDE9E6;
    min-width: 115px;
    cursor: pointer;
    height:40px;
    vertical-align: middle;
    color: #2B2B2B;
    font-size: 16px;
    opacity: 1;
}

.tabContainer {
    display: flex;
    height:40px;
    flex-wrap: wrap;
    padding-left:84px;
    margin:auto; 
    padding-right:84px;
    justify-content: center;
    width:86.86% 
}

.tabText {
    top: 9px;
    position: relative;
    opacity: 1
}

.tooltip {
    position: relative;
    top: 9px;
    opacity: 0.5;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    background-color: #c122fa;
    color: #fff;
    width: 340px;
    opacity: 1;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 4;
    top: 40px;
    left: 50%;
    margin-left: -120px;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    z-index: 4;
  }
  