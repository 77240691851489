.promoted-category-container{
    width: 30%;
    height: 114px;
    box-shadow: 0px 2px 3px #00000033;
    background: #fff;
    position: relative;
    cursor: pointer;
}
.promoted-category-container:hover {
  box-shadow: none;
  color : #5ED5C8
}

.diamond {
    transform: translate(0px, -47px);
    /* position: absolute; */
    /* left: 40%; */
}
.promoted-category-title {
  position: absolute;
  bottom: 13px;
  font-size: 22px;
  text-align: center;
  margin: auto;
  width: 100%;
}
