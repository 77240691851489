#messages-container {
    padding-left: 6.6%;
    padding-right: 6.6%;
    margin-top: 2px;
}
#messages-header {
    height: 50px;
    background: #3E3C41 0% 0% no-repeat padding-box;
    border-radius: 15px 15px 0px 0px;
    opacity: 1;
    display: flex;
    padding-left: 15px;
}
.button-header {
    background: #5C5762 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #fff;
    height: 32px;
    margin-right: 16px;
    border-radius: 16px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 10px;
    font-size: 14px;
    cursor: pointer;
}
#messages-list {
    display: flex;
    margin-top: 8px;
}

#messages-left-section {
    width: 34%;
    transform: translatex(-10px)
}
#messages-right-section {
    width: 66%;
}
#messages-left-section-header {
    display: flex;
    margin-left: 10px;
}
.messages-left-section-header-button {
    padding: 6px;
    border: 1px solid #CDE9E6;
    height: 18px;
    font-size: 12px;
    color: #9199C1;
    margin-right: 10px;
    text-transform: none;
    cursor: pointer;
}
.message-title {
    height: 96px;
    background-color: #F5FBFB;
    border: 1px solid #CDE9E6;
    width: 100%;
    cursor: pointer;
    position: relative;
    display: flex;
    padding: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.message-title-selected {
    height: 96px;
    background-color: #5ED5C8;
    border: 1px solid #CDE9E6;
    width: 100%;
    position: relative;
    padding: 10px;
    display: flex;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.message-title-image-container {
    height: 75px;
    width: 75px;
    background-color: #fff;
    margin-right: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 7px 14px 7px 14px;
}
.mesage-title-text-container {
    width: 65%;
}
.message-title-title {
    font-size: 20px;
    color: #fff;
    text-align: left;
    width: 100%;
}
.message-title-preview {
    font-size: 18px;
    color: #858585;
    text-align: left;
    width: 100%;

}
.message-title-date {
    font-size: 12px;
    color: #fff;
    text-align: right;
    width: 100%;
}
.message-title-triangle {
    width: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 15px solid #5ED5C8;
    position: absolute;
    top: 34px;
    right: -15px;
}

.message-title-circle {
    height: 30px;
    width: 30px;
    position: absolute;
    border-radius: 15px;
    left: -11px;
    top: 34px;
    background-color: #5ED5C8;
}
.message-title-circle-nselected {
    height: 30px;
    width: 30px;
    position: absolute;
    border-radius: 15px;
    left: -16px;
    top: 34px;
    background-color: #F5FBFB;
}
.message-title-check {
    height: 12px;
    width: 12px;
    margin: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #C6C6C6;
    vertical-align: middle;
    transform: translateY(1px);
}
.message-content-header {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 8px 34px 8px 34px;
    height: 76.5px;
    display: flex;
    justify-content: start;
    background-color: #F5FBFB;
    width: 100%;
}
.message-content-image {
    height: 60px;
    width: 60px;
    padding: 8px;
    margin-right: 2.7%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
}
.message-content-sender {
    color: #21062E;
    font-size: 24px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}
.message-content-title {
    color: #C122FA;
    font-size: 18px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
}
.message-content-header-button {
    height: 32px;
    padding: 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 14px;
    margin-right: 2.7%;
    margin-top: 22px;
    cursor: pointer;
}
.conversation-container {
    width: 100%;
    border: 1px solid #CDE9E6;
    margin-bottom: 15px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 10px;

}
.send-message {
    width: 100%;
    border: 1px solid #CDE9E6;
    height: 68px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
}
.message-line-owner {
    width: 72%;
    border: 1px solid #C122FA;
    border-radius: 10px;
    background-color: #C122FA;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 16px;
    color: #fff;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: left;
}
.message-line-other {
    width: 72%;
    border: 1px solid #C122FA;
    border-radius: 10px;
    background-color: #fff;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding: 16px;
    font-size: 16px;
    color: #545C84;
    margin-bottom: 10px;
    text-align: left;
}
  .left-section-mss::-webkit-scrollbar {
    display: transparent;
    z-index: -1;
  }
  
  .left-section-mss ::-moz-scrollbar {
    color: transparent;
    z-index: -1;
  }
  .left-section-mss {
    overflow-y: auto;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent;
  }
  .input-message {
    height: 38px;
    width: 80%;
    border: none;
    padding-left: 16px;
    background-color: #F5FBFB;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
  }
  .input-message::placeholder {
    color: #5ED5C8;
    font-size: 16px;
    transform: 'translateX(16px)';
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  .input-message:focus {
    outline: none
  }
  .send-message-bar {
    display: flex;
    width:90%;
    margin-top: 20px;
    height: 38px;
    border: none;
    border-radius: 20px;
    background-color: #F5FBFB;
  }
   /**/
  /* Hide scrollbar for IE, Edge add Firefox */
