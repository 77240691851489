.shop-custom-container {
    padding: 0 6.6%;
    width: 25px;
}
.my-name-helper {
    text-align: center;
    font-size: 14px;
    font-family: 'Jost', sans-serif;
    letter-spacing: 0px;
    color: #21062E;
    opacity: 1;
    margin-bottom: 10px;
}
.my-identity-field-label {
    text-align: left;
    font: normal normal medium 14px/18px 'Jost';
    letter-spacing: 0px;
    color: #21062E;
    opacity: 1;
    margin-bottom: 15px;
    font-weight: 600;
}
.my-contact-field-label {
    text-align: left;
    font: normal normal medium 14px/18px 'Jost';
    letter-spacing: 0px;
    color: #BF28FA;
    opacity: 1;
    margin-bottom: 15px;
}
.embedded-pdf > body > #scroller {
    overflow: hidden !important;
}
.validation-button-shop {
    width: 143px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    color: #fff
}