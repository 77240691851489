.pagination-container {
    height: 30px;
    color: #545C84;
    font-size: 16px;
    display: flex;
    margin: auto;
    margin-bottom: 60px;
}

.pagination-cell {
    width: 30px;
    border: 1px solid #CDE9E6;
    padding-top: 3px;
    cursor: pointer;
}

.pagination-text {
    width: 90px;
    border: 1px solid #CDE9E6;
    padding-top: 3px;
    cursor: pointer;
}