.store-header {
    width: 100%;
    padding: 15px 6.6% 9px 6.6%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.store-banner {
    width: 100%;
    max-height: 400px;
}
.store-container {
    transform: translateY(-20px);
}
.company-name {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    color: #0A0A0A;
    opacity: 1;
    margin-bottom: 10px;
}
.store-slogan {
    text-align: center;
    font-size: 20px;
    font-style: italic;
    opacity: 1;
    margin-bottom: 10px;
}
.ref-contact {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: #161616;
    opacity: 1;
    margin-bottom: 8px;
}
.store-phone {
    text-align: center;
    font-size: 18px;
    color: #0A0A0A;
}
.seller-products-store {
    padding: 0 8% 0 8%;
}
.seller-offers-title {
    color: #21062E;
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 30px;
}
.products-container-shop {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.store-adress-card {
    margin-top: 60px;
    font-size: 12px;
    font-weight: bold;
    color: #00274A;
    /* background-color: #FFFFFF1A;
    box-shadow: inset 0px 3px 6px #000000;
    border-radius: 4px; */
    height: 116px;
    display: flex;
    background-color: #FFFFFF1A;
    box-shadow: inset 0px 3px 6px #00000038;
    border-radius: 4px;
    min-width: 250px;
    opacity: 1;
}
.contact-store-line {
    display: flex;
    justify-content: center;
}
.store-adress-labels {
    width: 110px;
    text-align: right;
}
.store-adress-values {
    text-align: left;
}