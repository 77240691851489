.home-page-button {
    width: 30%;
    height: 70px;
    cursor: pointer;
    font-size: 18px;
    position: relative;
    display: flex
}
.home-page-button-disabled {
    width: 30%;
    height: 70px;
    cursor: pointer;
    font-size: 18px;
    position: relative;
    display: flex
}
.home-page-button-disabled .home-page-button-tooltip {
    visibility: hidden;
    background-color: rgb(62 60 65);
    width: 340px;
    opacity: 1;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 4;
    top: 40px;
    left: 50%;
    margin-left: -120px;
  }
  
  .home-page-button-disabled:hover .home-page-button-tooltip {
    visibility: visible;
    z-index: 4;
  }
  