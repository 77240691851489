.product-card-container {
    height: 380px;
    width: 255px;
    background-color: #FFFFFF;
    border: 1px #CDE9E6 solid;
    margin-right: 30px;
    margin-top: 30px;
    flex-shrink: 0;
    padding: 13px;
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.product-card-img {
    max-height: 69%;
    max-width: 100%;
    margin: auto;
}
.product-card-name {
    color: #22062E;
    font-size: 16px;
    width: 100%;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    max-lines: 2;
    text-align: center;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 17px;
    height: 32px;
    margin-bottom: 4px;
    max-height: 32px;
    -webkit-box-orient: vertical;}

.product-card-quantity {
    color: #9199C1;
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.product-card-description {
    color: #545C84;
    font-size: 13px;
    width: 100%;
    text-align: center;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 17px;
    height: 32px;
    margin-bottom: 4px;
    max-height: 32px;
    -webkit-box-orient: vertical;
}

.product-card-price {
    color: #C221FA;
    font-size: 18px;
    width: 100%;
    text-align: center;
    margin-bottom: 9px;
}

.product-card-unit {
    color: #545C84;
    font-size: 12px;
}

.fav-icon {
    position: absolute;
    top: 22px;
    right: 22px;
    height: 16px;
    width: 16px
}
.eye-icon{
    position: absolute;
    top: 0px;
    right: 8px;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
}
.edit-icon {
    position: absolute;
    bottom: 130px;
    right: 8px;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
}
.top-offer-badge {
    position: absolute;
    right: 203px;
    top: 0px;
}