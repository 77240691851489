.offers-list-container {
    padding: 0px 6.6%;
}

.filters-results-container {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}

.filters-container {
    width: 23%;
    background-color: #F5FBFB;
    margin-bottom: 30px;
    border: #CDE9E6 1px solid;
}
.filters-title {
    color: #21062E;
    font-size: 1.66em;
    width: 100%;
    margin: 15px auto 15px auto;
    height: 30px;
    text-align: center;
}
.filter-section-title {
    color: #63D4C7;
    font-size: 1.66em;
    text-align: start;
    padding-bottom: 16px;
}
.filter-section {
    padding: 15px;
}
#view-countries {
    font-size: 14px;
    text-decoration: underline;
    color:#63D4C7;
    cursor: pointer;
    text-align: start;
    padding-left: 16px;
    transform: translateY(-12px);
}
.offers-container {
    display: flex;
    flex-wrap: wrap;
    transform: translateY(-30px)
}

.hvr-border-fade {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
    box-shadow: inset 0 0 0 1px rgb(205, 233, 230), 0 0 1px rgba(0, 0, 0, 0);
    /* Hack to improve aliasing on mobile/tablet devices */
  }
  .hvr-border-fade:hover, .hvr-border-fade:focus, .hvr-border-fade:active {
    box-shadow: inset 0 0 0 3px #63D4C7, 0 0 1px rgba(0, 0, 0, 0);
    /* Hack to improve aliasing on mobile/tablet devices */
  }