.plans-container {
    display: flex;
    padding-right: 6.6%;
    padding-left: 6.6%;
}
.buyer-plan {
    width: 23%;
    margin-right: 2.5%;
}
.chose-plan {
    width: 48%;
    margin-right: 2.5%;
}
.seller-plan {
    width: 23%;
    margin-right: 2.5%;
}
.buyer-header {
    height: 100px;
    color: #fff;
    background-color: #F6256D;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 15px;
    padding-bottom: 15px;
}
.plan-header {
    height: 100px;
    color: #fff;
    background-color: #C025FA;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 15px;
    padding-bottom: 15px;
}
.seller-header {
    height: 100px;
    color: #fff;
    background-color: #5ED5C8;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 15px;
    padding-bottom: 15px;
}
.section-title {
    text-align: center;
    color: #fff;
    font-size: 26px;
    text-transform: uppercase;
}
.section-price {
    font-size: 26px;
    color: #22062E;
}
.price-postfix {
    color: #fff;
    font-size: 16px;
    margin-left: 8px;
}
.subscription-section-subhead {
    height: 100px;
    background-color: #F5FBFB;
    display: flex;
    border-left: 1px solid #CDE9E6;
    border-right: 1px solid #CDE9E6;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.row-subscription {
    height: 50px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-top: 1px solid #CDE9E6;
    border-left: 1px solid #CDE9E6;
    border-right: 1px solid #CDE9E6;
    text-align: center;
    font-size: 16px;
    color: #2B2B2B;
    padding-top: 12px;
}
.row-buyer {
    height: 50px;
    width: 100%;
    background-color: #F5FBFB;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    font-size: 16px;
    color: #2B2B2B;
    padding-top: 12px;
}
.subscription-button-border {
    width: 160px;
    height: 40px;
    background: transparent linear-gradient(305deg, #92E0D8 0%, #92E0D8 16%, #C122FA 80%, #FA2365 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    justify-content: center;
    margin: auto;
    /* margin-top: 16px; */
    /* transform: translateY(36px); */
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.subscription-button {
    width: 158px;
    height: 38px;
    font-size: 16px;
    cursor: pointer;
    margin: auto;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 8px;
    opacity: 1;
    text-transform: uppercase;
}
.chose-plan-cert {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding-top: 27px;
    margin: auto;
    width: 100%;
    justify-content: center;
}
.section-bottom {
    height: 110px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid #CDE9E6;
    border-top: none;
}
.section-bottom-button {
    height: 40px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    padding-top: 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    text-transform: uppercase;
}

.coupon-question {
    font-size: 24px;
    font-weight: bold;
    color: #707070;
    margin-bottom: 5px;
    margin-right: auto;
    margin-left: auto;
}
.enter-coupon {
    font-size: 18px;
    color: #2D2D2D;
    margin-bottom: 30px;
    margin-right: auto;
    margin-left: auto;
}
.verify-code {
    font-size: 18px;
    color: #fff;
    width: 303px;
    height: 57px;
    line-height: 57px;
    background-color: #BF23F9;
    margin-bottom: 41px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}
.no-coupon {
    color : #F6256D;
    font-size: 16px;
    width: 235px;
    height: 40px;
    line-height: 40px;
    border: 1px solid #F6256D;
    margin-bottom: 40px;
    margin-right: auto;
    margin-left: auto;
    cursor: pointer;
}
.did-u-know {
    color: #5ED5C8;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 25px;
    margin-right: auto;
    margin-left: auto;
}
.manually-verified {
    color: #5ED5C8;
    font-size: 18px;
    margin-right: auto;
    margin-left: auto;
}