.invoice-line {
    margin: auto;
    margin-bottom: 20px;
    width: 70%;
    height: 60px;
    background-color: #F5FBFB;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.invoice-download {
    width: 105px;
    height: 30px;
    background: transparent linear-gradient(45deg, #9916C7 0%, #9916C7 1%, #C032FC 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 30px;
    cursor: pointer;
    text-decoration: none;
}