.miniatures-container {
    display: flex;
    overflow-x: scroll;
    justify-content: start;
    width: 70%;
    overflow: hidden;
    scroll-behavior: smooth;
}
.miniature {
    margin-right: 22px;
    cursor: pointer;
    border: 1px solid #CDE9E6
}
.selected-image-container {
    /*border: 1px #CDE9E6 solid;*/
    padding: 20px;
    height: 310px;
    margin-bottom: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.selected-image {
    /*max-height: 80%;
    max-width: 80%;*/
    max-height: 100%;
    max-width: 100%;
    margin: auto;
}