.selected-s {
    border-bottom:none;
    border-left: 1px solid #CDE9E6;
    border-right: 1px solid #CDE9E6;
    border-top: 1px solid #CDE9E6;
    /* min-width:11%; */
    max-width: 30%;
    min-width: 150px;
    border-radius: 5px 5px 0px 0px;
    height:40px;
    vertical-align: middle;
    color: #63D4C7;
    font-size: 16px;
    opacity: 1;
}

.notSelected-s {
    border-bottom:1px solid #CDE9E6;
    /* min-width:11%; */
    max-width: 30%;
    min-width: 150px;
    cursor: pointer;
    height:40px;
    vertical-align: middle;
    color: #2B2B2B;
    font-size: 16px;
    opacity: 1;
}

.tabContainerd {
    display: flex;
    height:40px;
    flex-wrap: wrap;
    margin: auto; 
    padding-top: 30px;
    padding-right:84px;
    justify-content: start;
    width:100% 
}

.tabText {
    top: 9px;
    position: relative;
}