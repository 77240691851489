.home-first-section-container {
    background-color: #F5FBFB;
    width: 100%;
    margin-bottom: 30px;
}
.home-page {
    padding: 0px 6.6%;
}
.home-first-section {
    display: flex;
    padding: 0px 6.6%;
    justify-content: center;
}

.home-promoted-filler {
    width: 25.5%;
}
.home-promoted-filler-none {
    width: 0%;
}
.home-promoted-container {
    width: 74.3%;
    min-width: 820px;
    /* height: 700px */
}
.promoted-categories-container {
    width: 99%;
    display: flex;
    margin: auto;
    justify-content: space-between;
}

.homepage-buttons-container {
    display: flex;
    margin: auto;
    margin-top: 30px;
    width: 99%;
    justify-content: space-between;
    margin-bottom: 60px;
}