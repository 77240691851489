.offer-container {
    width: 87% !important;
    position: relative;
    margin: auto !important;
}
.description-title {
    color: '#21062E';
    font-size: 26px;
    text-align: start;
}
.price {
    color: #C221FA;
    font-size: 26px;
    text-align: start;
}
.unit {
  color: #545C84;
  font-size: 12px;
}
.rating-container {
  text-align: start;
}
.rating-icon {
    margin-right: 2px
}
.rate-text {
    margin-left: 30px;
    color: #BD2BFA;
    size: 16px;
    cursor: pointer;
}
.cell-title {
    border: none !important;
    font-size: 16px !important;
    color: #3C3C41 !important
}
.cell-value {
    border: none !important;
    font-size: 16px !important;
    color: #22062E !important
}
.container-desc {
    margin-top: 30px;
    /* display: flex !important; */
}
.text-container {
    text-align: start;
    min-width: 225px;
}
.buttons-cont {
    /* position: absolute; */
    display: flex;
    margin-top: 30px;
    /* bottom: 0px; */
    min-width: 340px;
    justify-content: space-between;
}
.button-desc {
    width: 20%;
    height:40px;
    font-size:16px !important
}
.button-ic {
    margin-right: 10px;
}
.seller-card {
    height: 72%;
    width: 55%;
    min-width: 244px;
    padding-top: 16px;
    background-color: #F5FBFB;
    border: 1px solid #CDE9E6
}
.seller-data-title {
    color: #63D4C7;
    margin-bottom: 16px;
    font-size: '20px';
    width: 100%;
    text-align: center;
}
.seller-data-table {
    display: flex;
    justify-content: center;
    padding: 0px 15px 0px 15px;
    text-align: center;
}

.section-seller-data {
    width: 50%;
    text-align: start;
}
.section-seller-datas {
    text-align: start;
}

.d-label {
  font-size: 14px;
  color: #3C3C41;
  margin-bottom: 4px;
}
.d-value {
    font-size: 14px;
    color: #22062E;
    margin-bottom: 4px;
    padding-left: 4px;
}
.hour {
    color: #3C3C41;
    margin-top:16px;
    font-size: 14px;
    text-align: center;
    width: 100%;
}
.days-container {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto
}
.day-cell {
    background-color: #fff;
    color: #3C3C41;
    width: 14%;
    font-size: 10px;
    cursor: pointer;
    height: 20px;
    border: 1px #CDE9E6 solid;
    padding-top: 4px;
}
.day-cell-selected {
    background-color: #5CD6CB;
    color: #F5FBFB;
    width: 14%;
    font-size: 10px;
    height: 20px;
    border: 1px #CDE9E6 solid;
    padding-top: 4px;
}
.hour-helper {
   color: #3C3C41;
   font-size: 13px; 
   margin-top: 16px;
   text-align: center;
   width: 100%;
   margin-bottom: 4px;
}
.unlock-section {
    height: 40px;
    background-color: #5CD6CB;
    color: #fff;
    width: 100%;
    position: relative;
    bottom: 0px;
    cursor: pointer;
}
.other-offers {
    background-color: #F5FAF9;
    width: 100%;
    height: 500px
}
.other-offers-title {
    font-size: 26px;
    color: #21062E;
    text-align: center;
    width: 100%;
    top: 20px;
    position: relative;
}
#cards-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: start;
    width: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth
}